import {getFormConfig} from "./formConfig";

export const getTableColumn = vm => {
  const formConfigList = getFormConfig().filterFormConfig.filter(item => item.label);
  const list = [
    {
      field: "userId",
      title: "userId",
      tag:'userId'
    },
    {
      field: "groupId",
      title: "group",
      tag:'groupId'
    },
    {
      field: "siteId",
      title: "site",
      tag:'siteId'
    },
  ]

  const fixedList = [
    { type: 'seq', title: '序号', width: '60', tag: 'seq' },
  ];

  const opList = [
    {
      field: "edit",
      title: "操作",
      fixed: 'right',
      width: '200',
      tag: 'edit',
      _renderType: true,
      render: (h, {data}) => {
        let { row } = data;
        return (
          <div class={'edit'}>
            <el-button type="text" onClick={() => { vm.openModal('editUser',row) }}>配置</el-button>
          </div>
        );
      }
    },
  ]
  return {
    tableColumn:[...fixedList, ...list, ...opList]
  }
}