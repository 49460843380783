import Api from '@api';

export const getFormConfig = (vm) => {
    return {
      filterFormConfig:[
        {
          "key": "USER_ID",
          "label": "USER_ID",
          "type": "input",
          "placeholder": "USER_ID",
          "checked": false
        },
      ]
    }
}

