import Api from '@api';
import { getFormConfig } from "./configs/formConfig";
import { getTableColumn } from './configs/tableConfig';
import { getUserLists } from "../../../api/auth";
import formTableInitMixins from '@/mixins/formTableInit.js';
import habitMixins from '@/mixins/habit.js';
import editUser from "./blocks/editUser/editUser.vue";
import addUser from "./blocks/addUser/addUser.vue";


export default {
  name: 'userManage',
  components: {
    editUser,
    addUser
  },
  mixins: [habitMixins, formTableInitMixins],
  data: function () {
    const formConfig = getFormConfig(this).filterFormConfig;
    const tableColumn = getTableColumn(this).tableColumn;

    return {
      formData: {
        USER_ID: ''
      }, //筛选条件
      filterFormConfig: formConfig,
      conditions: formConfig, // 增加查询条件的值
      importData: [], // table 数据
      tableColumn, // 原本的表格配置
      tableColumnConfigList: tableColumn,
      dialog: null,
      injectData: {},
      modal: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.form = JSON.parse(JSON.stringify(this.injectData))
      // this.getList();
    })
  },
  methods: {
    /**
     * @description 初始化数据
     */
    async getList() {
      const params = {
        ...this.$refs.filterForm.formData,
        PAGE_NUM: await this.$refs.configTable.page.currentPage,
        PAGE_SIZE: await this.$refs.configTable.page.pageSize
      }
      const { data, status, message } = await getUserLists(params)
      if (status === 200) {
        this.importData = data.list
        this.$refs.configTable.page.total = Number(data.total)
      }
    },
    /*
    * @param modal  模态框名称
    * @param params  参数
    * */
    openModal(modal, params) {
      this.modal = modal
      this.injectData = params
    },
    close() {
      this.modal = null
      this.getList();
    },
    refresh() {
      this.getList();
    },
    //新增用户
    addUser() {
      this.modal = 'addUser'
    }
  }
}