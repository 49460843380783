import Api from '@api';
import {getUserRoleList, updateUserRoleList} from "../../../../../api/auth";
import {getActiveClientGroups} from "../../../../../api/client";

export default {
  name: 'editRole',
  components: {
  },
  props: {
    injectData: {
      type: Object,
      default: () => {
        return {
          roleId: ''
        }
      }
    },
  },
  data() {
    return {
      form:{
        name:'',
        CLIENT_VIS_GROUP: ''
      },
      UNSELECTED: [],
      SELECTED: [],
      titles:['待选择角色','已有角色'],
      allclientgroups: [],
    }
  },
  mounted(){
    this.getUserRoles();
    this.getActiveClientGroupList();
  },
  methods: {
    async handleConfirm() {
      const params = {
        USER_ID:this.injectData.userId,
        ROLE_IDS:this.SELECTED,
        CLIENT_VIS_GROUP: this.form.CLIENT_VIS_GROUP
      }
      const {status,data,success} = await updateUserRoleList(params)
      if(status == 200){
        this.$emit('close')
        this.$message.success(success)
      }
    },
    async getUserRoles(){
      const params = {
        userId:this.injectData.userId
      }
      const {status,data} = await getUserRoleList(params)
      if(status == 200){
        this.SELECTED = data.SELECTED
        this.UNSELECTED = [...data.SELECTED.map(
          item=>{
            return {
              key:item,
              label:item
            }
          }
        ),...data.UNSELECTED.map(
          item=>{
            return {
              key:item,
              label:item
            }
          }
        )]
      }
    },
    async getActiveClientGroupList() {
      const params = {
        
      }
      const {status,data} = await getActiveClientGroups(params)
      if(status == 200){
        //this.allclientgroups = data.data
        this.allclientgroups = [...data.map(
          item=>{
            return {
              key:item.clientGroup,
              label:item.lientGroup
            }
          }
        )];
		
	  	console.log("this.allclientgroups=" + this.allclientgroups);
      }
    },
    handleClose(params = {}) {
      this.$emit('close', params)
    }
  }
}